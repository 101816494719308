.custom-multiselect__multi-value__label {
  padding-right: 10px !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

.custom-multiselect__multi-value {
  border-radius: 4px !important;
  background-color: #d4bdda !important;
}

.custom-multiselect__option--is-focused {
  background-color: rgba(49, 49, 49, 0.2) !important;
}

.custom-multiselect__option {
  color: #161a1a !important;
  font-weight: normal !important;
  font-size: 16px !important;
}

.custom-multiselect__menu-list {
  background-color: #fff !important;
  border: 1px solid #d5d5d5 !important;
}
