.ReactModal__Overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.ReactModal__Content--after-open {
  overflow-y: scroll !important;
  max-height: 80%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.ReactModal__Content--after-open::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
